// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-josewlpg-2-js": () => import("./../../../src/pages/josewlpg2.js" /* webpackChunkName: "component---src-pages-josewlpg-2-js" */),
  "component---src-pages-jswlem-js": () => import("./../../../src/pages/jswlem.js" /* webpackChunkName: "component---src-pages-jswlem-js" */),
  "component---src-pages-tq-wl-js": () => import("./../../../src/pages/tq-wl.js" /* webpackChunkName: "component---src-pages-tq-wl-js" */)
}

